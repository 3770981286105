// GERMAN(Switzerland) de_CH

export default {
  // START Aria translation
  aria_enter: `Beginn des Spendenbereichs`,
  aria_flow: `{{routeName}}. Abschnitt {{sectionIndex}} von {{totalSteps}}`,
  // END Aria translation

  _verification: 'Überprüfung läuft...',
  _timeout:
    'Es ist ein Timeout-Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
  _confirmation_boundary_error: `Ein Fehler ist aufgetreten. Ihre Transaktion wurde jedoch verarbeitet und erscheint auf Ihrem Kontoauszug.`,
  _unable_loading_error: `Der Inhalt kann derzeit nicht geladen werden.`,
  _configuration_error: 'Es ist ein Konfigurationsfehler aufgetreten',
  _loading: `Lädt...`,
  _loading_image: `Bild wird geladen...`,
  _select: `Ausgewählt`,
  _month: `Monat`,
  _year: `Jahr`,
  _or: `oder`,

  amount: '<1> {{currencySymbol}} {{amount}} </ 1>', // not in use

  processing: {
    donation_donate: 'Deine Spende wird bearbeitet...',
    donation_subscribe: 'Bearbeitung Ihres Abonnements...',
    contact: `Deine Kontaktdaten werden aktualisiert...`,
    paymentInfo: `Deine Zahlungsinformationen werden aktualisiert...`,
    failure_message: `Zahlungsprozess konnte nicht abgeschlossen werden `,
    intent_failure_message: 'Die Zahlungsabsicht konnte nicht erstellt werden',
    fetching_pages: `Fundraising-Seiten werden aufgerufen...`,
    recaptcha_token_error:
      'Der reCAPTCHA-Token konnte derzeit nicht abgerufen werden. Bitte versuchen Sie es später noch einmal.',
    recaptcha_failure:
      'Ungültige Anfrage, fehlerhaftes Recaptcha-Token. Bitte überprüfen Sie Ihre Angaben noch einmal und versuchen Sie es erneut.',
  },

  image: {
    no_alt:
      'Es wurde ein dynamisches Bild geladen, es wurde jedoch kein Alternativtext bereitgestellt',
  },

  title: {
    how_often_donate: `Wie oft möchtest Du spenden?`,
    select_amount_donate: `Wähle den Betrag, den Du spenden möchtest:`,
    select_amount_subscribe: 'Sie haben derzeit ein Abonnement für Folgendes:',
    direct_your_gift: `Wie möchtest Du Deine Spende einsetzen? `,
    bank: `Bank`,
    basc: 'BACS Direct Debit',
    select_payment_method: `Wähle eine Zahlungsmethode aus`,
    wallets: 'Geldbörsen',
    apple_pay: `Apple Pay`,
    google_pay: `Google Pay`,
    card: `Karte`,
    lifetimeGiving: `Laufzeit`,
    givingHistory: `Spendenhistorie`,
    thisYearGiving: `Dieses Jahr`,
    lastGiftAmount: `Letzte Spendensumme`,
    recurringGift: `Laufende Spende`,
    creditCard: `Kreditkarte`,
    childRecurringGift: `Status der laufenden Spende für Kinder`,
    next: `Weiter`,
    last: `Letzte`,
    cardType: `Kreditkartentyp`,
    expiry: `Ablaufdatum`,
    cancelled: `Gelöscht `,
    gift_aid: `Spendenhilfe`,
    donor_details: `Angaben zum Spender`,
    subscribe_details: 'Abonnentendetails',
    payment_details: `Zahlungsoptionen`,
    additionalInfo: `Zusätzliche Informationen`,
    membership: `Mitgliedschaft`,
    show_support: `Teile eine Nachricht`,
    share_on: `Teilen via`, // CP
    share_generosity: `Teile Deine Grosszügigkeit und inspiriere andere:`,
    help_further: `Du möchtest mehr tun?`,
  },

  button: {
    other_btn: `Andere`,
    cancel_btn: `Löschen`,
    search_btn: `Suche`,
    new_gift_btn: `Neue Spende`,
    create_new_contact_btn: `Spende für neuen Spender anlegen`,
    update_contact_info: `Kontaktinformationen aktualisieren`,
    update_payment_info: `Zahlungsinformationen aktualisieren`,
    update_payment_schedule: `Zahlungszeitplan aktualisieren`,
    edit_btn: 'Kampagne bearbeiten',
    view_btn: `Ansicht`,
    share_btn: 'Meine Seite teilen',
    save_changes: `Änderungen speichern`,
  },

  navigation: {
    next_btn: 'Weiter',
    prev_btn: `Vorherige`,
    update_btn: `Aktualisieren`,
    finish_btn: 'Beenden',
    donate_btn: 'Spenden',
    subscribe_btn: 'Abonnement',
    membership_btn: `Mitgliedschaft`,
    ptx_verification: 'Verifizieren',
    authorization:
      'Durch Abschluss dieses Schritts autorisieren Sie die Speicherung dieses Kartenkontos für: <strong><1>{{amount}}</1> pro <2>{{recurring}}</2>, bis zur Kündigung</strong>',
  },

  link: {
    read_more: `Mehr anzeigen`,
    show_less: `Weniger anzeigen`,
    donate_monthly_link: `Ich möchte monatlich spenden`,
    donate_annually_link: `Ich möchte jährlich spenden`,
    privacy_policy: 'Datenschutzbestimmungen',
    terms_conditions: 'Geschäftsbedingungen',
    your_donation: `Deine Spende <1>{{currency}}</1>`,
    your_monthly_donation: `Deine monatliche Spende <1>{{currency}}</1>`,
    your_annually_donation: `Deine jährliche Spende <1>{{currency}}</1>`,
    your_subscription: 'Deine Abonnement <1>{{currency}}</1>',
    your_monthly_subscription: `Deine monatliche Abonnement <1>{{currency}}</1>`,
    your_annually_subscription: `Deine jährliche Abonnement <1>{{currency}}</1>`,
    your_membership: `Deine Mitgliedschaft <1>{{currency}}</1>`,
    giving: '<1>{{currency}}</1> geben',
  },

  forms: {
    no: `Nein`,
    yes: 'Ja',
    required: 'Erforderlich',
  },

  contactForm: {
    honour_dedication: `Im Namen von…`,
    memory_dedication: `In Erinnerung an…`,
    no_send_tribute: `Nein\ndanke`,
    email_send_tribute: `Ja, bitte\nper E-Mail`,
    mail_send_tribute: `Ja, bitte\nper Post `,
    company_gift: `Dies ist eine Unternehmensspende`,
    company: `Firmenname`,
    company_valication: `Bitte gib den Namen Deines Unternehmens ein`,
    company_help_text: `Name des Unternehmens oder der Organisation, der auf der Bestätigung und/oder Quittung angegeben werden soll.`,
    first_name: 'Vorname',
    first_name_validation: `Bitte gib Deinen Vornamen ein`,
    last_name: 'Nachname',
    last_name_validation: `Bitte gib Deinen Nachnamen ein`,
    anonymous_help_text: `Dein Name und Deine Spenderdaten werden nicht öffentlich gemacht; die gemeinnützige Organisation erhält jedoch weiterhin Deine Daten.`,
    email: `E-Mail`,
    email_validation: `Bitte gib eine gültige E-Mailadresse ein`,
    email_help_text: `Die Bestätigung über Deine Spende und/oder Quittung wird Dir per E-Mail zugeschickt. `,
    email_help_text_subscribe:
      'Jegliche Bestätigung oder Mitteilung zu diesem Abonnement wird hier gesendet.',
    phone: `Telefon`,
    phone_validation: `Bitte gib eine gültige Telefonnummer ohne Nachkommastellen oder Bindestriche ein`,
    mobile: 'Mobiltelefon',
    mobile_validation:
      'Bitte geben Sie eine gültige Mobiltelefonnummer ohne Dezimalstellen oder Bindestriche ein',
    postal_code: `Postleitzahl`,
    postal_code_validation: `Bitte gib eine gültige Postleitzahl ein`,
    address: `Adresse 1`,
    address_validation: `Bitte gib Deine Adresse ein`,
    address_auto_complete_help_text:
      'Für das Feld „Adresse 1“ ist die automatische Vervollständigung aktiviert. Für die Bereitstellung von Vorschlägen sind 5 Zeichen erforderlich.',
    address2: `Adresse 2`,
    city: 'Stadt',
    city_validation: `Bitte gib Deinen Wohnort ein`,
    province: `Kanton`,
    province_validation: `Bitte gib den Kanton an`,
    country: `Land`,
    country_validation: `Bitte gib ein gültiges Land ein`,
    employer_matches_checkbox: `Mein Arbeitgeber wird die Spende verdoppeln`,
    employer: `Mein Arbeitgeber`,
    honouree_label: 'Bitte geben Sie ihren Namen ein',
    honouree_validation: `Bitte gib den Namen der Person ein`, // used for employer validation - missing?
    dedication_checkbox: `Ja, ich spende im Namen einer anderen Peron`,
    tribute_type: `Möchtest Du Post von uns erhalten? `,
    recipient_email: `E-Mailadresse des Empfängers`,
    recipient_email_validation: `Bitte gib die E-Mailadresse oder die Adresse des Empfängers ein`,
    addressee_name: `Post senden an`,
    addressee_name_validation: `Bitte gib den Namen des Empfängers ein`,
    special_message: `Teile mit der Person eine persönliche Nachricht (optional)`,
    double_donation: 'Sehen Sie, ob Ihr Unternehmen Ihre Spende verdoppelt!',
  },

  questionsForm: {
    required_message: 'Antworten auf die folgenden Fragen sind erforderlich.',
    custom_questions_error: 'Antworten auf alle Fragen sind erforderlich',
  },

  paymentForm: {
    // Bank
    transit_no: ` Transit-Nummer`,
    tran_no_validation: `Bitte geben Sie eine gültige Transitnummer ein`,
    routing_no: `Bankleitzahl `,
    routing_no_validation: `Bitte gib eine gültige Bankleitzahl ein`,
    sort_code: ` Sortiernummer`,
    sort_code_validation: `Bitte geben Sie eine gültige Bankleitzahl ein`,
    bsb_no: `IBAN-Nummer`,
    bsb_no_validation: `Bitte geben Sie eine gültige IBAN-Nummer ein`,
    bank_check: `Überweisung`,
    bank_name: `Name der Bank`,
    bank_name_validation: `Der Name der Bank ist erforderlich`,
    bank_name_help_text: `Der Bankname darf nicht länger als 18 Zeichen sein`,
    acc_no: `Kontonummer/IBAN`,
    acc_no_validation: `Bitte gib eine gültige Kontonummer ein`,
    inst_no: ` Nummer der Einrichtung`,
    inst_no_validation: `Bitte geben Sie eine gültige Institutsnummer ein`,
    // Credit Card
    card_number: `Kreditkartennummer`,
    card_validation: `Bitte gib eine gültige Kreditkartennummer ein`,
    expiry_date_mm: 'MM',
    expiry_date_mm_validation:
      'Bitte geben Sie ein gültiges Ablaufdatum an (MM). Die erste Ziffer bei einstelligen Monatszahlen ist die 0.',
    expiry_date_yy: 'JJ',
    expiry_date_yy_validation:
      'Bitte geben Sie ein gültiges Ablaufdatum an (JJ).',
    expiry_date_mmyy: 'MM/JJ',
    card_security_code: 'CVV',
    card_security_code_validation: `Bitte gib Deine Prüfnummer ein`,
    use_existing_credit_card: `Bitte gib eine gültige Kreditkarte an`,
    // PTX
    ptx_verified_message: 'Validierung erfolgreich',
    adyen_error_message: `Es ist ein Zahlungsfehler vom Typ {{ resultCode }} aufgetreten. Bitte überprüfen Sie Ihre Informationen noch einmal oder wählen Sie eine andere Zahlungsmethode aus.`,
  },

  frequencySelector: {
    donate_single: 'Einmalige\nSpende',
    donate_monthly: 'Monatlich\nspenden',
    donate_annually: `Jährliche\nSpende`,
    subscribe_single: 'Einmaliges\nAbonnement',
    subscribe_monthly: 'Monatlich\nabonnieren',
    subscribe_annually: `Jährlich\nabonnieren`,
  },

  amountSelector: {
    minimum_donation_of: `Mindestspendenbetrag`,
  },

  designationSelector: {
    greatest_need: `Grösster Bedarf`,
    selection_label: `Andere`,
    order_label: `Bitte auswählen`,
  },

  giftAid: {
    giftAid_donate_checkbox: `Ja, ich möchte einen Betrag (25% meiner Spende) zusätzlich spenden.`,
    giftAid_subscribe_checkbox: `Ja, ich zahle Steuern im Vereinigten Königreich und möchte mit Gift Aid dafür sorgen, dass es mehr bedeutet.`,
    // giftAid_checkbox:
    // 'Ja, ich möchte zusätzlich zu meiner Spende von <2>{{donation}}</2> eine Geschenkhilfe in Höhe von <1>{{gift}}</1> (25% der Spende) hinzufügen.',
  },

  confirmation: {
    thank_you: 'Danke, {{name}}',
    donation_received_OCP: `Deine Spende ist bei uns eingegangen`,
    donation_received: `Deine Spende in Höhe von <1>{{donationAmount}}</1> ist eingegangen.`,
    subscription_received: `Deine Abonnement in Höhe von <1>{{donationAmount}}</1> ist eingegangen.`,
    receipt: `Eine Bestätigung über Deine Spende wird an folgende E-Mailadresse geschickt <em>{{email}}</em>.`,
    redirectMessage: `Du wirst weitergeleitet in: `,
    seconds: `Sekunden`,
  },

  // CampaignStats
  // src\components\molecules\campaignStats\index.tsx
  amount_raised: `Eingenommener Betrag`, // CP
  goal: `Ziel`, // CP
  of_goal: 'von', // CP
  supporters: `Unterstützer/innen`, // CP
  days_left: 'Verbleibende Tage', // CP

  Amount: `Betrag`,
  Name: `Name`,
  Team: `Team`,
  Total: `Gesamt`,

  individual_subtitle: `Starte Deine eigene Spendenaktion unterhalb.`,
  team_subtitle: `Starte Deine Team-Spendenaktion unterhalb. `,
  campaign_title: `Auf geht's!`,

  individual_page_name_label: `Name Deiner persönlichen Fundraising-Seite`,
  team_page_name_label: `Name Deiner Team-Fundraising-Seite`,
  team_member_page_name_label: `Team-Mitglieder Deiner Fundraising-Seite `,
  individual_page_goal_label: `Individuelles Spendenziel`,
  team_page_goal_label: `Spendenziel Deines Teams `,
  team_member_page_goal_label: `Team-Mitglieder Spendenziel`,

  team_campaign_name: `Team-Fundraising-Name`,
  campaign_name: `Name der Fundraising-Seite`,
  campaign_name_validation: `Bitte gib einen gültigen Namen für Deine Spendenkampagne ein`,
  campaign_goal: `Spendenziel`,
  team_campaign_goal: `Spendenziel Deines Teams `,
  campaign_url: `Kampagnen-URL`,

  create_campaign_btn: `Erstelle eine Fundraising-Seite`,
  update_campaign_btn: `Änderungen speichern`,
  delete_campaign_btn: `Seite löschen`,
  cancel_changes_btn: `Änderungen löschen`,

  password: `Passwort`,
  password_validation: `Bitte gib Dein Passwort ein`,
  password_reset_success: `Anweisungen zum Zurücksetzen des Passworts wurden an folgende E-Mailadresse geschickt {{email}}.`,
  forgot_password: `Passwort vergessen? `,
  old_password: `Altes Passwort`,
  new_password: `Neues Passwort`,
  forgot_password_title: `Passwort zurücksetzen`,
  forgot_password_btn: `Anweisungen zum Zurücksetzen senden`,
  change_password_title: `Passwort ändern `,
  failed_password_change: `Altes Passwort stimmt nicht überein`,
  success_password_change: `Das Passwort wurde erfolgreich geändert`,

  sign_in: 'Anmelden',
  sign_up: `Anmeldung `,
  start_your: `Starte Deine Spendenaktion `,
  dont_have: `Du hast noch keinen Account? `,
  already_have: `Du hast schon einen Account? `,
  go_back: `Gehe zurück `,

  edit_campaign: `Kampagne bearbeiten`,
  log_out: `Ausloggen`,
  activity: `Aktivität`,
  settings: `Einstellungen`,

  // EditCampaignBox
  // src\components\organism\editCampaignBox\index.tsx
  characters_remaining: `{{count}} verbleibende Zeichen`,
  start_a_campaign_btn: `Eine Kampagne starten`,
  campaign_photo: `Bild Deiner Fundraising-Seite`,
  recommended_photo_size: `Empfohlene Grösse des Fotos: 1240 x 600 Pixel`,
  edit_profile_title: `Profil aktualisieren`,
  edit_campaign_title_individual: `Bearbeite Deine persönliche Fundraising-Seite`,
  edit_campaign_title_team: `Bearbeite Deine Team-Fundraising-Seite`,
  edit_campaign_title_team_member: `Bearbeite die Team-Mitglieder Deiner Fundraising-Seite`,
  return_back_btn: `Zurück zum Profil`,
  changes_saved_btn: `Alle Änderungen wurden übernommen!`,
  no_changes_btn: `Keine zu speichernden Änderungen`,
  browse: `surfen`,
  drag_drop_pic: `Bild kann per Drag-and-drop hochgeladen werden`,
  cancel_image_upload: `Bild-Upload abbrechen`,

  // DeleteCampaignModal
  // src\components\atoms\deleteCampaignModal\index.tsx
  deletion_confirmation_question: `Bist Du Dir sicher, dass Du die Fundraising-Seite löschen möchtest?`,

  // P2pRibbon
  // src\components\molecules\p2pRibbon\index.tsx
  join_team_btn: `Ich trete dem Team bei`,

  // CampaignForm
  // src\components\organism\campaignForm\index.tsx
  join_team_subtitle: `Beitreten {{name}}`,
  join_as_individual_btn: `Als Einzelperson teilnehmen`,
  create_a_team_btn: `Stelle ein Team zusammen`,
  create_a_new_team_btn: `Stelle ein neues Team zusammen`,
  welcome: `Herzliche Willkommen`,
  individual_select_message: `Bitte auswählen, ob Du ein Team unterstützen oder Dein Profil überprüfen möchtest.`,
  member_select_message: `Bitte auswählen, ob Du ein neues Team unterstützen oder Dein Profil überprüfen möchtest.`,
  team_select_message: `Bitte überprüfe Deine Angaben.`,
  ind_or_team_message: `Bitte auswählen, wenn Du Deine persönliche Spendenaktion starten oder ein Fundraising-Team gründen möchtest. `,
  click_here: `Hier klicken`,
  prefer_individual: `Wenn Du eine Spendenaktion im Team starten möchtest, `,
  prefer_team: `Wenn Du eine persönliche Spendenaktion starten möchtest, `,

  // CampaignSupportersDetails
  // src\components\organism\campaignSupportersDetails\index.tsx
  donated_by: `Gespendet von `,
  campaign_supporters: `Kampagnenunterstützer:innen`,
  campaign_sponsor: `Unsere grosszügigen Sponsoren und Partner`,

  // ShowSupportDetails
  // src\components\organism\showSupportDetails\index.tsx
  display_name: `Gib hier einen Namen ein - er wird auf der Fundraising-Seite erscheinen`,
  leave_message: `Teile eine Nachricht - diese wird auf der Seite der Organisation oder des Spenders erscheinen, damit sie wissen, dass sie eine Spende von Dir erhalten haben (optional)`,
  support_display_all: `Bitte meinen Namen, meine Nachricht und meinen Spendenbetrag anzeigen. `,
  support_hide_amount: `Bitte nur meinen Namen und meine Nachricht anzeigen (Spendenbetrag wird nicht aufgeführt). `,
  support_hide_name: `Bitte nur meinen Spendenbetrag und meine Nachricht anzeigen (Name wird aufgeführt). `,
  support_hide_all: `Bitte meinen Namen, meine Nachricht und meinen Spendenbetrag nicht anzeigen. `,
  email_exists: `Diese E-Mailadresse existiert bereits`,

  anonymous: `Anonym`,
  fundraising_team_for: `Fundraising-Team für`,
  fundraising_for: `Spendensammeln für`,
  fundraising_team_member: `Mitglied von`,

  // LeaderboardsDetails
  // src\components\organism\leaderboardsDetails\index.tsx
  team_members: `Team-Mitglieder`,
  no_pages_to_display: `Es existieren keine Fundraising-Seiten, die angezeigt werden können. `,
  fundraising_teams: `Fundraising-Teams`,
  individual_fundraisers: `Individuelle Spendenaktion`,

  // CampaignRow
  // src\components\molecules\campaignRow\index.tsx
  individual_member_of: `Mitglied von:`,
  team_member_of: `Teammitglied von:`,
  role_team_member: `(Rolle: Team-Mitglied)`,
  role_team_captain: `(Rolle: Team-Captain)`,

  // CampaignsListBox
  // src\components\molecules\campaignsListBox\index.tsx
  no_individual_pages: `Du hast keine persönlichen Fundraising-Seiten.`,
  no_team_pages: `Du hast keine Team-Fundraising-Seiten. `,

  // ContactSearch
  // src\components\molecules\contactSearch\index.tsx
  constituentNumber: `Mitgliedsnummer`,
  update_saved_message: `Änderungen wurden erfolgreich gespeichert`,

  // ErrorPage
  // src\components\organism\errorPage\index.tsx
  title_404: `405`,
  subtitle_404: `Sorry, wir konnten diese Seite nicht finden.`,
  message_404: `Zurück zu - `,
  home_page: `Homepage`,

  // OCPLayout
  // src\components\layouts\ocpLayout\index.tsx
  new_gift_title: `Neue Spende anlegen`,

  // PaymentSchedule
  // src\components\pages\PaymentSchedule.tsx
  cancel_donation: `Spende löschen`,
  back: `zurück`,
  notPaymentScheduleMessage: `Kein Zahlungsplan verfügbar`,

  // OCPGate
  // src\components\pages\OCPGate.tsx
  start_by: `Beginne mit der Eingabe eines`,

  // CancelPaymentSchedule
  // src\components\molecules\cancelPaymentSchedule\index.tsx
  note: `Beachte: `,
  FeltPressure: `Spender:in fühlte sich vom Fundraiser unter Druck gesetzt`,
  NoLongerAfford: `Es sich nicht mehr leisten können`,
  UnderMinimumWage: `Unter Mindestalter`,
  SpouseObjected: `Ehepartner hat widersprochen`,
  ChangedMind: `Ich habe meine Meinung geändert`,
  IntendedShortTerm: `Ich möchte kurzzeitig spenden`,
  IntendedOneTime: `Ich möchte einmalig spenden`,
  Deceased: `Verstorben`,
  Misunderstood: `Missverstandene Verpflichtung`,
  OtherCharities: `An andere Organisationen spenden`,
  PaymentDeclined: `Zahlung abgelehnt`,
  Complaint: `Beschwerde`,
  AccountClosed: `Account wurde gelöscht`,
  AlreadyDonating: `Ich spende bereits`,
  NoReason: `Ich möchte keinen Grund angeben`,

  // TimeoutModal CP
  // src\components\molecules\timeoutModal\index.tsx
  continue_session: `angemeldet bleiben`,
  session_timeout: `Sitzung ist abgelaufen`,
  session_message: `Die aktuelle Sitzung wird in <1>{{countdown}}</1> Sekunden ablaufen.`,
  continue_confirmation: `Möchtest Du fortfahren? `,

  // DonateBox & DonateButton CP
  master_donate_btn: `Spende für diese Kampagne`,
  team_donate_btn: `Spende für dieses Team`,
  team_member_donate_btn: `Spende für {{name}} Fundraiser`,

  // CampaignSupportersCard & Card
  // src\components\molecules\campaignSupportersCard\index.tsx
  // src\components\atoms\card\index.tsx
  donation_through: `Spende wurde getätigt über`,
  donation: `Spende`,

  // OCP DonationGroup > Accordion
  why_donate_monthly: `Welche Wirkung hat eine monatliche Spende? `,

  // ContactHistory
  // src\components\molecules\contactHistory\index.tsx
  Completed: `Abgeschlossen`,
  Terminated: `Storniert`,
  Failed: `Fehlgeschlagen`,
  TransactionRefund: `Rückerstattung`,
  InProgress: `In Bearbeitung`,

  // UpdatePaymentInfo
  // src\components\pages\UpdatePaymentInfo.tsx
  notPaymentMethodsMessage: `Keine Zahlungsmethode verfügbar`,

  // MembershipDetails
  // src\components\organism\membershipDetails\index.tsx
  include_membership_checkbox: `Meine Mitgliedschaft in meiner <1>{{donation}}</1> <2>{{donationFrequency}}</2> Spende einschliessen.`,
  membership_order_label: `Bitte auswählen (optional)`,

  // HelpFurther
  // src\components\molecules\helpFurther\index.tsx
  get_involved_title: `Engagiere Dich`,
  set_up_fundraising_btn: `Spendenkampagne aufsetzen`,
  start_fundraising_accordian_title: `Kampagne unterstützen`,

  // OrderSummary
  // src\components\atoms\orderSummary\index.tsx
  summary_link_donate_description: `Klicke auf "Deine Spende", um den Betrag Deiner Spende zu ändern`,
  summary_link_subscribe_description: `Klicken Sie auf „Ihr Abonnement“, um den Betrag zu ändern`,
  unexpected_error_message:
    'Ein Fehler ist aufgetreten. Ihre Transaktion wurde jedoch verarbeitet und erscheint auf Ihrem Kontoauszug. <1>Versuchen Sie es nicht noch einmal.</1><br /><br /><2>Klicken Sie hier</2> oder wenden Sie sich umgehend an unser Spenderservice-Team, um uns mitzuteilen, dass Sie diese Nachricht erhalten haben.',
  applePay_error: `Apple Pay ist nur verfügbar, wenn es über iOS-kompatible Geräte (iPhone/iPad/Mac) aktiviert ist.`,
  googlePay_error: `Google Pay ist auf diesem Gerät nicht aktiviert oder verfügbar. `,

  // AdditionalInfoDetails
  // src\components\organism\additionalInfoDetails\index.tsx
  additional_info_msg: `Hier kannst Du einen Kommentar zu Deiner Spende hinterlassen.`,
  notes_or_direction: `Teilen Sie unten weitere Kommentare oder Anweisungen zu Ihrer Spende mit`,
  nols_additional_info_msg:
    'Hier kannst Du einen Kommentar zu Deiner Spende hinterlassen.',
  nols_notes_or_direction: '',

  // SignInForm
  // src\components\organism\signInForm\index.tsx
  add_profile_pic: `Füge ein Profilbild hinzu: `,
  upload_profile_picture_checkbox: `Ich möchte ein Profilbild hochladen`,

  // SeachBar
  // src\components\atoms\searchBar\index.tsx
  sort_by: `Sortiert nach`,
  top_fundraisers: `Top-Fundraiser`,
  most_recent: `Aktuellste`,
  alphabetical: `Alphabetisch`,

  // CAMPAIGN PAGES!!!!!!!!!!
  // ProfileImg
  // src\components\atoms\profileImg\index.tsx
  change_profile_picture: `Profilbild ändern`,
  start_p2p_btn: 'Fundraising beginnen',
  // P2P START actionButtonSet and P2P Action
  title_donate_now: `Ich möchte für diese Kampagne spenden`,
  team_title_donate_now: `Ich möchte für dieses Team spenden`,
  title_start_fundraiser: `Ich möchte meine eigene Spendenaktion starten`,
  title_join_team: `Ich möchte im Team Spenden sammeln`,
  title_view_team: `Schau Dir die Fundraising-Aktivitäten Deines Teams an`,
  title_join_individual: `Ich möchte meine eigene Spendenaktion starten`,
  title_view_individual: `Deine Fundraising-Aktivitäten ansehen`,
  master_title_join_team: `Ich möchte im Team Spenden sammeln`,
  team_title_join_team: `Ich möchte diesem Team beitreten`,
  donate_this_campaign: `Für diese Kampagne spenden`,
  donate_now: 'Spende\njetzt',
  start_fundraiser: `Jetzt eine Spendenaktion starten`,
  create_fundraiser: `Eine Spendenaktion\nanlegen`,
  master_join_team: `Ein Team\nanlegen`,
  team_join_team: `Diesem Team\nbeitreten`,
  view_team: `Deine Team-Seite\nansehen`,
  join_individual: `Als Einzelperson\nteilnehmen`,
  view_individual: `Deine Seite\nansehen`,
  // END P2P actionButtonSet and P2P Action
  view_dashboard: `Dashboard anzeigen`,
  donate_today: `Spende\nheute`,
  select_heading: `Bitte wähle Deine Spende aus`,
  choose_details: `Wähle die Details aus, die Du auf der Seite der Organisation oder der Spendenaktion anzeigen möchtest:`,
  required_pwd: `Bitte gib Dein Passwort ein`,
  pwd_match: `Das Passwort muss mindestens 8 Zeichen enthalten, davon ein Grossbuchstabe, eine Zahl und ein Sonderzeichen.`,
  // END CAMPAIGN PAGES!!!!!!!!!!
};
