// FRENCH (fr)

export default {
  // START Aria translation
  aria_enter: `Commencer la section en lien avec le don `,
  aria_flow: `Commencer {{routeName}} section. Section {{sectionIndex}} sur {{totalSteps}}`,
  // END Aria translation

  _verification: 'Vérification en cours...',
  _timeout: `Une erreur de temporisation s'est produite. Veuillez réessayer plus tard.`,
  _confirmation_boundary_error: `Une erreur est survenue. Mais votre transaction a été traitée et apparaîtra sur votre relevé.`,
  _unable_loading_error: `Impossible de charger le contenu pour le moment.`,
  _configuration_error: `Une erreur de configuration s'est produite`,
  _loading: `Chargement `,
  _loading_image: `Chargement de l'image`,
  _select: `Sélectionner `,
  _month: `Mois`,
  _year: `année `,
  _or: `ou `,

  amount: `<1>{{amount}}{{currencySymbol}}</1>`, // not in use

  processing: {
    donation_donate: 'Nous traitons votre don.',
    donation_subscribe: 'Traitement de votre inscription.',
    contact: `Mise à jour de vos informations de contact ...`,
    paymentInfo: `Mise à jour de vos informations de paiement ...`,
    failure_message: `Echec du paiement `,
    intent_failure_message: `Échec de la création de l'intention de paiement`,
    // contact_not_found: `… non trouvé `,
    fetching_pages: `Recherche des pages de collecte`,
    recaptcha_token_error:
      'Échec de la récupération du jeton reCAPTCHA pour le moment. Veuillez réessayer plus tard.',
    recaptcha_failure:
      'Mauvaise demande, mauvais jeton recaptcha. Veuillez vérifier vos informations et réessayer.',
  },

  image: {
    no_alt: `Une image dynamique a été chargée mais aucun texte alternatif n'a été fourni`,
  },

  title: {
    how_often_donate: `À quelle fréquence souhaitez-vous faire un don ?`,
    select_amount_donate: `Sélectionner le montant que vous souhaitez donner :`,
    select_amount_subscribe: `Vous êtes actuellement abonné pour donner :`,
    direct_your_gift: `Vers quelle cause souhaitez-vous diriger votre don ?`,
    bank: `Banque `,
    basc: 'BACS Direct Debit',
    select_payment_method: `Selectionnez votre moyen de paiement `,
    apple_pay: `Apple Pay`,
    google_pay: `Google Pay `,
    card: `Carte`,
    lifetimeGiving: `à vie `,
    givingHistory: `Historique des dons `,
    thisYearGiving: `Cette année `,
    lastGiftAmount: `Dernier don reçu `,
    recurringGift: `Don récurrent `,
    creditCard: `Carte de crédit `,
    childRecurringGift: `Statut du don récurrent pour un enfant `,
    next: `Continuer`,
    last: `Dernier `,
    cardType: `Type de carte `,
    expiry: `Expiration `,
    cancelled: `Annulé `,
    gift_aid: `Aide au don `,
    donor_details: `Détails du donateur/de la donatrice `,
    subscribe_details: `Détails de l'abonné`,
    payment_details: `Options de paiement`,
    additionalInfo: `Informations additionnelles `,
    membership: `Adhésion `,
    show_support: `Envoyer un message de soutien `,
    share_on: `Partager sur `, // CP
    share_generosity: `Partager votre générosité et inspirer d'autres personnes :`,
    help_further: `Vous souhaitez contribuer plus ? `,
  },

  button: {
    other_btn: `Autre `,
    cancel_btn: `Annuler `,
    search_btn: `Rechercher `,
    new_gift_btn: `Nouveau don `,
    create_new_contact_btn: `Créer un don pour un nouveau donateur `,
    update_contact_info: `Mise à jour des informations de contact`,
    update_payment_info: `Mise à jour des informations de paiement`,
    update_payment_schedule: `Mise à jour du planning des paiements `,
    edit_btn: 'Modifier la campagne',
    view_btn: `Vue `,
    share_btn: 'Partager ma page',
    save_changes: `Sauvegarder les modifications `,
  },

  navigation: {
    next_btn: 'Continuer',
    prev_btn: `Précédent `,
    update_btn: `Mettre à jour `,
    finish_btn: 'Terminer',
    donate_btn: 'Faire un don',
    subscribe_btn: 'Abonnement',
    membership_btn: `Adhésion `,
    ptx_verification: 'Verify',
    authorization: `En complétant cette étape, vous autorisez le stockage de ce compte carte pour : <strong><1>{{amount}}</1> / par <2>{{recurring}}</2>, jusqu'à annulation</strong>`,
  },

  link: {
    read_more: `Lire plus `,
    show_less: `Montrer moins `,
    donate_monthly_link: `Je souhaite faire un don mensuel `,
    donate_annually_link: `Je souhaite faire un don annuel  `,
    privacy_policy: 'Politique de confidentialité',
    terms_conditions: 'Termes et conditions',
    your_donation: `Votre don <1>{{currency}}</1>`,
    your_monthly_donation: `Votre don mensuel <1>{{currency}}</1>`,
    your_annually_donation: `Votre don annuel <1>{{currency}}</1>`,
    your_subscription: `Votre abonnement <1>{{currency}}</1>`,
    your_monthly_subscription: `Votre abonnement mensuel <1>{{currency}}</1>`,
    your_annually_subscription: `Votre abonnement annuel <1>{{currency}}</1>`,
    your_membership: `Votre adhésion <1>{{currency}}</1>`,
    giving: 'Donner <1>{{currency}}</1>',
  },

  forms: {
    no: `Non`,
    yes: 'Oui',
    required: 'Requis',
  },

  contactForm: {
    honour_dedication: `En honneur de …`,
    memory_dedication: `Faire un don à la mémoire d'une personne`,
    no_send_tribute: `Non \nmerci `,
    email_send_tribute: `Oui, \npar email`,
    mail_send_tribute: `Oui, \npar la poste `,
    company_gift: `C'est un don d'une entreprise `,
    company: `Nom de l'entreprise`,
    company_help_text: `Le nom de l'entreprise ou de l'organisation qui apparaîtra sur l'accusé de réception et/ou le reçu.`,
    company_valication: `Entrer le nom de l'entreprise `,
    first_name: 'Prénom',
    first_name_validation: `Prénom `,
    last_name: 'Nom',
    last_name_validation: `Nom `,
    anonymous_help_text: `Votre nom et vos informations n'apparaitront dans auncune communication publique, cependant, l'organisation caritative y aura accès. `,
    email: `E-mail`,
    email_validation: `Adresse email valide `,
    email_help_text_donate: `Votre accusé de réception et/ou reçu sera envoyé par email ici.`,
    email_help_text_subscribe:
      'Tout accusé de réception ou communication concernant cet abonnement sera envoyé ici.',
    phone: `Téléphone `,
    phone_validation: `Numéro de téléphone valide sans ponctuation/signes`,
    mobile: 'Mobile',
    mobile_validation:
      'Veuillez saisir un numéro de mobile valide sans décimales ni tirets',
    postal_code: `Code Postal`,
    postal_code_validation: `Code postal valide `,
    address: `Adresse 1`,
    address_validation: `Votre adresse postale `,
    address_auto_complete_help_text: `Le champ Adresse 1 a la saisie semi-automatique activée. 5 caractères sont requis avant que des suggestions soient fournies.`,
    address2: `Adresse 2 `,
    city: 'Ville',
    city_validation: `Votre ville `,
    province: `Province `,
    province_validation: `Votre province `,
    country: `Pays`,
    country_validation: `Pays `,
    employer_matches_checkbox: `Mon employeur`,
    employer: `Mon employeur`,
    honouree_label: 'Veuillez entrer son nom',
    honouree_validation: `Veuillez indiquer le nom de la personne`, // used for employer validation - missing?
    dedication_checkbox: `Oui, mon don est en honneur ou à la mémoire d'une personne`,
    tribute_type: `Souhaitez-vous que nous envoyions une carte ?`,
    recipient_email: `Adresse email de la personne `,
    recipient_email_validation: `Entrer l'email du destinataire `,
    addressee_name: `Envoyer une carte à `,
    addressee_name_validation: `Veuillez indiquer le nom du destinataire `,
    special_message: `Laisser un message spécial ici (facultatif)`,
    special_message_validation: `Laisser un message au destinataire `, // not in use
    double_donation: 'Voyez si votre entreprise égalera votre don !',
  },

  questionsForm: {
    required_message: 'Les réponses aux questions ci-dessous sont requises.',
    custom_questions_error: 'Les réponses à toutes les questions sont requises',
  },

  paymentForm: {
    // Bank
    transit_no: `Numéro de transit `,
    tran_no_validation: `Entrer un numéro de transit valide`,
    routing_no: `Numéro de routage `,
    routing_no_validation: `Entrer un numéro de routage valide `,
    sort_code: `Sort Code `,
    sort_code_validation: `Entrer un numéro de Sort Code valide `,
    bsb_no: `Code IBAN`,
    bsb_no_validation: `Entrer un numéro IBAN valide`,
    bank_check: `transfert`,
    bank_name: `Nom de la banque `,
    bank_name_validation: `Nom de la banque obligatoire `,
    bank_name_help_text: `Le nom de la banque ne peut pas dépasser 18 caractères`,
    acc_no: `Numéro de compte `,
    acc_no_validation: `Entrer un numéro de compte valide `,
    inst_no: `Numéro de l'établissement `,
    inst_no_validation: `Entrer un numéro d'établissement valide `,
    // Credit Card
    card_number: `Numéro de carte `,
    card_validation: `Merci d'indiquer un numéro de carte valide`,
    expiry_date_mm: 'MM',
    expiry_date_mm_validation:
      "Merci d'indiquer une date d'expiration valide (MM). Insérer la valeur numérique du mois en ajoutant un zéro aux valeurs à un chiffre.",
    expiry_date_yy: 'AA',
    expiry_date_yy_validation:
      "Merci d'indiquer une date d'expiration valide (YY).",
    expiry_date_mmyy: 'MM/AA',
    card_security_code: 'CVV',
    card_security_code_validation: `Entrer le code CVV`,
    use_existing_credit_card: `Utiliser la carte de crédit enregistrée`,
    // PTX
    ptx_verified_message: 'Validation Successful',
    adyen_error_message: `Un échec de paiement de type : {{ resultCode }} s'est produit, veuillez vérifier vos informations ou sélectionner un autre mode de paiement.`,
  },

  amountSelector: {
    minimum_donation_of: `Don minimum de`,
  },

  designationSelector: {
    greatest_need: `Le plus grand besoin `,
    selection_label: `Autre `,
    order_label: `Sélectionner`,
  },

  frequencySelector: {
    donate_single: 'Faire un\ndon une fois',
    donate_monthly: 'Faire un\ndon mensuel',
    donate_annually: `Faire un\ndon annuel `,
    subscribe_single: 'Abonnement\nunique',
    subscribe_monthly: 'Abonnez-vous\nmensuellement',
    subscribe_annually: 'Abonnez-vous\nannuellement',
  },

  giftAid: {
    giftAid_donate_checkbox: `Oui, je souhaite ajouter une aide financière en plus de mon don.`,
    giftAid_subscribe_checkbox: `Oui, je paie des impôts au Royaume-Uni et je veux que cela ait plus de sens avec Gift Aid.`,
    // giftAid_checkbox:
    //   "Oui, j'aimerais ajouter une aide-cadeau de <1>{{gift}}</1> (25% du don) en plus de mon don de <2>{{donation}}</2>.",
  },

  confirmation: {
    thank_you: 'Merci, {{name}}',
    donation_received_OCP: `Votre don a bien été reçu `,
    donation_received: `Votre don de <1>{{donationAmount}}</1> a bien été reçu.`,
    subscription_received: `Votre abonnement de <1>{{donationAmount}}</1> a bien été reçu.`,
    receipt: `Une confirmation sera envoyée à {{email}}.`,
    redirectMessage: `Vous serez redirigé dans :`,
    seconds: `Secondes `,
  },

  read_less: `Lire moins `, // not in use
  expiry_date_mm_label: `Expiration MM`, // not in use
  expiry_date_yy_label: `Expiration AA`, // not in use
  wallets: 'Portefeuilles',
  eft: `EFT - Transfert de Fonds Électronique `, // not in use
  bank: `Banque `, // Title
  basc: 'BACS Direct Debit', // Title
  chequing: `compte chèques `, // not in use
  savings: `épargne `, // not in use
  select_acc_type: `Choisir un type de compte `, // not in use

  // V1 start
  additionalItems: `Articles supplémentaires`,
  backtoHomepage: `Valider`,
  bookTickets: `Acheter des billets`,

  cityCP: `Ville`,
  companyCP: `Entreprise`,
  // confirmation: `Confirmation`, // not in use
  contactDetails: `Données personnelles`,
  CVV: `CVV`,
  donateButton: `Je fais un don`,
  donateInHonorOf: `Faire un don en l'honneur d'une personne`,
  donateMonthly: `Don mensuel`,
  donateOnce: `Don unique`,
  edit1: `Donner/ Faire un don`,
  edit2: `modifier`,

  eventDetails: `Informations détaillées sur l'événement`,
  expiryDate: `Date d'expiration`,
  expiryDateValidation: `Merci d'indiquer une date d'expiration valide.`,
  expiryMM: `MM`,
  expiryMMSingleValidation: `Merci d'indiquer une date d'expiration valide (MM). Ajouter un zéro devant les mois à un seul chiffre.`,
  expiryYY: `AA`,
  expiryYYSingleValidation: `Merci d'indiquer une date d'expiration valide (YY).`,
  firstNameCP: `Prénom`,
  free: `Libre`,

  goBackCP: `Retour`,
  lastName: `Nom`,
  location: `Lieu`,

  monthly: `par mois`,
  myEmployerMatchesDonations: `Mon employeur jumelle des dons`,
  nameofHonouree: `Merci d'entrer leur nom`,

  noDedication: `Aucune dédicace`,
  nowBookingCP: `Réserver maintenant`,
  ofGoal: `de`,
  orderSummary: `Récapitulatif de la commande`,
  orderAmounts: `Total`,
  paymentDetails: `Détails de paiement`,
  paymentError: `Nous n'avons pas pu procéder à votre paiement. Merci de réessayer.`,
  privacyPolicy: `Politique de confidentialité`,
  salutation: `Salutation`,
  share: `Partager`,
  signIn: `S'enregistrer`,
  soldOut: `Épuisé`,
  specialMessage: `Partager un message spécial`,
  startFundraising: `Commencer`,
  suite: `Suite`,

  termsandConditionsCP: `Termes et conditions`,
  thankyou: `Merci`,
  tickectValidation: `Pour continuer, vous devez choisir au moins 1 ticket.`,
  whyDonateMonthly: `Pourquoi faire un don mensuel ?`,

  // V1 end

  // EP
  donation_details: `Détails du don `,
  gift_details: `Aide au don `,
  membership_details: `Adhésion `,
  guest_details: `Informations donateur `,
  guest_preferences: `Préférences donateur `,
  purchase_tickets: `Enregistrer `,
  purchase_donation: `Faire un don`,
  questions_details: `S'impliquer `,
  navigation_purchase: `S'inscrire `,
  navigation_preferences: `Informations donateur `,
  navigation_buyer_details: `Contact`,
  navigation_payment: `Paiement `,
  navigation_home: `Retour à la page d'accueil `,

  donation_success: `Don effectué avec succès `,

  honour: `Rendre hommage `,
  memory: `À la mémoire `,
  show_honour_message: `Vous avez indiqué vouloir effectuer ce don en l'honneur d'une personne `,
  show_memory_message: `Vous avez indiqué vouloir effectuer ce don à la mémoire d'une personne `,
  acknowledgment_checkbox: `Envoyer une notification à la personne`,

  minimum_amount: `Montant minimum `,
  membership_message: `Oui, je souhaite ajouter une adhésion en plus de mon don`,
  gift_designation: `Comment souhaitez-vous utiliser votre don ?`,
  national: `National `,
  local: `Local `,
  invoice: `Facture `,
  linkBank: `Lien bancaire`,
  total: `Total `,
  donate_subheading: `Soutenir notre mission`,
  guest_details_subheading: `Informations donateur `,
  order_complete_heading: `Votre inscription est complète `,

  previous: `Précédent `,
  transaction_fees: `Frais de transaction `,

  complete_purchase: `Terminer mon inscription  `,
  no_items: `Aucun objet disponible `,

  emailHelptext: `Votre reçu sera envoyé par email ici.`,

  cancel: `Annuler `,

  close: `fermer `,
  saveAndClose: `Sauvegarder et fermer `,
  saveChanges: `Sauvegarder les modifications `,
  guest: `Donateur/donatrice `,
  preferences: `Préférences `,
  preferencesInvalid: `Merci de compléter les préférences pour chaque donateur/donatrice `,
  loading_message: `Chargement `,

  // EP end

  // CampaignStats
  // src\components\molecules\campaignStats\index.tsx
  amount_raised: `Récoltés `, // CP
  goal: `Objectif`, // CP
  of_goal: "de l'objectif", // CP
  supporters: `Avec le soutien de`, // CP
  days_left: 'Jours Restants', // CP

  Amount: `Montant `,
  Name: `Nom `,
  Team: `Équipe `,
  Total: `Total `,

  // share_btn: 'Partager', // Deprecated Component: ShareMenu - molecules\shareMenu\index.tsx
  // now_booking: 'Réserver Maintenant', // EP Deprecated Component: EventOverview - organism\eventOverview\index.tsx
  // book_tickets: `Enregistrer `, // EP Deprecated Component: EventOverview - organism\eventOverview\index.tsx

  // donate: `Faire un don`,
  // donate_now: 'Faire un\ndon maintenant',

  // totalAmount: `Total `,
  // totalAmount: `{{currencySymbol}}{{orderAmounts}}`, // NOT IN USE

  // top_fundraising_teams: `Meilleures équipes de collecte de dons `,
  // top_individual_fundraisers: `Meilleurs collecteurs et collectrices individuels`,
  loading_image: `Chargement de l'image`,

  confirmationDetails_donation_received: `Votre don de <1>{{donationAmount}}</1> a bien été reçu`,
  confirmationDetails_receipt: `Une confirmation sera envoyée à {{email}}`,

  processingCD: `Mise à jour de vos informations de contact `,
  processingPaymentSchedule: `Mise à jour de vos informations de paiement `,
  share_on: `Partager sur `,
  use_existing_credit_card: `Utiliser la carte de crédit enregistrée`,
  select: `Sélectionner `,
  last: `Dernier `,
  cardType: `Type de carte `,
  expiry: `Expiration `,
  apple_pay: `Apple Pay`,
  google_pay: `Google Pay `,
  minimum_donation_of: `Don minimum de`,
  top_fundraising_teams: `Meilleures équipes de collecte de dons `,
  top_individual_fundraisers: `Meilleurs collecteurs et collectrices individuels`,
  individual_subtitle: `Commencez votre collecte de dons individuelle ci-dessous `,
  team_subtitle: `Commencez votre collecte de dons en équipe ci-dessous `,
  campaign_title: `C'est parti !`,
  // individual_fundraiser_btn: `Créer un page individuelle `,
  // team_fundraiser_btn: `Créer un page pour une équipe  `,
  // individual_fundraiser_tab: `Pages individuelles`,
  // team_fundraiser_tab: `Pages équipe`,

  individual_page_name_label: `Nom de page pour la collecte individuelle`,
  team_page_name_label: `Nom de page pour la collecte en équipe `,
  team_member_page_name_label: `Nom de page pour les membres de l'équipe de collecte `,
  individual_page_goal_label: `Objectif de collecte de dons individuel`,
  team_page_goal_label: `Objectif de collecte de dons par équipe `,
  team_member_page_goal_label: `Objectif de collecte de dons par membre `,

  team_campaign_name: `Nom de l'équipe de collecte de dons `,
  campaign_name: `Nom de la page de collecte de dons `,
  campaign_name_validation: `Entrer un nom valide pour la campagne de collecte de dons`,
  campaign_goal: `Objectif de la collecte de dons `,
  team_campaign_goal: `Objectif de collecte de dons par équipe `,
  // campaign_goal_validation: `Entrer un objectif de collecte de dons valide`,
  campaign_url: `URL de la campagne `,
  // start_date: `Date de début `,
  // end_date: `Date de fin `,

  create_campaign_btn: `Créer un page de collecte de dons `,
  update_campaign_btn: `Sauvegarder les modifications `,
  delete_campaign_btn: `Effacer la page `,
  cancel_changes_btn: `Annuler les modifications `,

  password: `Mot de passe `,
  password_validation: `Entrez votre mot de passe`,
  // password_requirements: `Votre mot de passe doit contenir au minimum 8 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial.`,
  password_reset_success: `Les instructions pour réinitialiser le mot de passe ont été envoyées à {{email}}`,
  forgot_password: `Mot de passe oublié ?`,
  old_password: `Ancien mot de passe `,
  new_password: `Nouveau mot de passe`,
  // old_password_validation: `Entrer votre ancien mot de passe `,
  // new_password_validation: `Entrer votre nouveau de passe `,
  forgot_password_title: `Réinitialiser le mot de passe `,
  forgot_password_btn: `Envoyer les instructions pour la réinitialisation `,
  change_password_title: `Modifier le mot de passe `,
  failed_password_change: `L'ancien mot de passe de correspond pas `,
  success_password_change: `Mot de passe mis à jour `,
  // credentials_error: `Adresse email ou mot de passe non valide`,

  sign_in: 'Se connecter',
  sign_up: `Inscrivez-vous `,
  start_your: `Commencez votre collecte de dons !`,
  dont_have: `Vous n'avez pas de compte ?`,
  already_have: `Vous avez déjà un compte ?`,
  go_back: `Retour `,

  edit_campaign: `Modifier la campagne `,
  log_out: `Déconnexion `,
  activity: `Activité `,
  settings: `Paramètres `,

  // Deprecated Component: QuickLinksBox - src\components\molecules\quickLinksBox\index.tsx
  // download_media_kit: `Télécharger le dossier de presse`,
  // learn_to_fundraise: `Apprenez à collecter des dons `,
  // help_center: `Centre d'assistance `,
  // quick_links: `Liens directs `,

  // Deprecated Component: DonateBox - src\components\molecules\donateBox\index.tsx
  // days_left_to_donate: `Jours restants pour faire des dons `,
  // days_left_to_fundraise: `Jours restants pour collecter des dons `,

  // team_campaigns: `Campagnes d'équipe`,
  // your_campaigns: `Vos pages de collecte de fonds `,

  // birthday: `Anniversaire (facultatif)`,

  // profile_photo: `Photo de profil`,
  // upload_photo: `Télécharger une photo `,

  // Deprecated Component: ManagePrefsBox - ManagePrefsBox - src\components\molecules\managePrefsBox\index.tsx
  // manage_prefs_title: `Gestion des préférences `,
  // donation_updates: `Nouvelles concernant les dons et évènements à venir `,
  // phone_campaigns: `Campagnes téléphoniques `,
  // updates_via_mail: `Mise à jour via email `,

  // edit_profile: `Modifier le profil `,
  // my_profile: `Mon profil `,

  // mission_statement: `Définition de la mission `,

  // EditCampaignBox
  // src\components\organism\editCampaignBox\index.tsx
  characters_remaining: `{{count}} nombre de caractères restants `,
  start_a_campaign_btn: `Lancer une campagne `,
  campaign_photo: `Image de la page de collecte `,
  recommended_photo_size: `Taille recommandée pour la photo 1240 x 600 pixels`,
  edit_profile_title: `Modifier/mettre à jour le profil`,
  edit_campaign_title_individual: `Modifier la page de collecte individuelle `,
  edit_campaign_title_team: `Modifier la page de collecte par équipe `,
  edit_campaign_title_team_member: `Modifier la page membre d'équipe de la collecte `,
  return_back_btn: `Retourner au profil `,
  changes_saved_btn: `Modifications sauvegardées `,
  no_changes_btn: `Aucune modification sauvegardée `,
  browse: `Naviguer `,
  drag_drop_pic: `Glisser et déposer la photo à télécharger`,
  cancel_image_upload: `Annuler le téléchargement de l'image `,

  // DeleteCampaignModal
  // src\components\atoms\deleteCampaignModal\index.tsx
  deletion_confirmation_question: `Êtes-vous sûr de vouloir supprimer cette page de collecte ?`,

  // P2pRibbon
  // src\components\molecules\p2pRibbon\index.tsx
  join_team_btn: `Rejoindre l'équipe `,

  // CampaignForm
  // src\components\organism\campaignForm\index.tsx
  join_team_subtitle: `Rejoindre {{name}}`,
  join_as_individual_btn: `Rejoindre en tant qu'individuel `,
  create_a_team_btn: `Créer une équipe `,
  create_a_new_team_btn: `Créer une nouvelle équipe `,
  welcome: `Bienvenue`,
  individual_select_message: `Souhaitez-vous collecter des fonds pour une équipe ou modifier votre profil.`,
  member_select_message: `Souhaitez-vous collecter des fonds pour une nouvelle équipe ou modifier votre profil.`,
  team_select_message: `Modifier votre profil`,
  ind_or_team_message: `Souhaitez-vous collecter des fonds individuellement ou créer une équipe de collecte de dons.`,
  click_here: `Cliquer ici `,
  prefer_individual: `Si vous préférez lancer une collecte de dons en équipe,`,
  prefer_team: `Si vous préférez lancer une collecte de dons individuelle, `,

  // CampaignSupportersDetails
  // src\components\organism\campaignSupportersDetails\index.tsx
  // donated_by: `Dons effectués par `,
  campaign_supporters: `Supporters de la campagne `,
  campaign_sponsor: `Nos généreux sponsors et partenaires `,

  // ShowSupportDetails
  // src\components\organism\showSupportDetails\index.tsx
  display_name: `Entrer le nom qui apparaitra sur la page de collecte ici`,
  leave_message: `Envoyer votre message de soutien - il apparaîtra sur la page l'organisation ou de la campagne collecte afin de les informer de votre don (facultatif).`,
  support_display_all: `Afficher mon nom, messages et montants.`,
  support_hide_amount: `Afficher uniquement mon nom et mes messages (masquer les montants) `,
  support_hide_name: `Afficher uniquement le montant des dons et les messages (masquer mon nom)`,
  support_hide_all: `Non, masquer mon nom, les messages et le montant des dons `,
  // support_help_text: `Les messages sans nom seront visibles sous "Dons anonymes"`,
  // hide_name_checkbox: `Ne pas mentionner mon nom sur la page de la campagne `,
  // show_support_subtitle: `Informer les collecteurs de dons de votre don et de sa raison `,
  // creating_campaign: `Créer votre campagne …`,
  // failed_campaign_creation: `Echec de la création de campagne`,
  // join_team_checkbox: `Permettre à d'autres personnes de rejoindre votre équipe `,

  email_exists: `Cette adresse email est déjà utilisée `,

  // Deprecated Component: P2pTabs - src\components\molecules\p2pTabs\index.tsx
  // about_campaign: `Au sujet de la campagne `,
  // teams: `Les équipes `,
  // participants: `Les participants `,
  // no_team_members: `Personne ne s'est inscrit comme membre de cette équipe `,

  anonymous: `Anonyme`,
  fundraising_team_for: `Equipe de collecte de dons pour :`,
  fundraising_for: `Collecte de dons pour :`,
  fundraising_team_member: `Membre de ;`,

  // fundraising_dates: `Dates de collecte de dons `,

  // LeaderboardsDetails
  // src\components\organism\leaderboardsDetails\index.tsx
  team_members: `Les membres de l'équipe `,
  no_pages_to_display: `Aucune page de collecte à afficher `,
  fundraising_teams: `Les équipes de collecte`,
  individual_fundraisers: `Les récolteurs et récolteuses de dons`,
  // total_amount_raised: `Montant total collecté :`,

  // CampaignRow
  // src\components\molecules\campaignRow\index.tsx
  individual_member_of: `Membre individuel de :`,
  team_member_of: `Membre de l'équipe de :`,
  role_team_member: `(rôle : Membre d'équipe) `,
  role_team_captain: `(rôle : Capitaine d'équipe) `,

  // CampaignsListBox
  // src\components\molecules\campaignsListBox\index.tsx
  no_individual_pages: `Vous n'avez pas de pages de collecte individuelles`,
  no_team_pages: `Vous n'avez pas de pages de collecte en équipe`,

  // expired: `Expiré (ou expirée)`,
  // share_on_facebook: `Partager sur Facebook `,
  // share_on_twitter: `Partager sur Twitter `,
  // share_on_linkedin: `Partager sur LinkedIn `,

  // ContactSearch
  // src\components\molecules\contactSearch\index.tsx
  constituentNumber: `numéro de ….`,
  update_saved_message: `Mise à jour sauvegardée avec succès `,

  // ErrorPage
  // src\components\organism\errorPage\index.tsx
  title_404: `404`,
  subtitle_404: `Désolés, nous n'avons pas retrouvé votre page.`,
  message_404: `Ramenez-moi à -`,
  home_page: `Page d'accueil `,

  // OCPLayout
  // src\components\layouts\ocpLayout\index.tsx
  new_gift_title: `Créer un nouveau don `,

  // PaymentSchedule
  // src\components\pages\PaymentSchedule.tsx
  cancel_donation: `Annuler le don `,
  back: `Retour `,
  notPaymentScheduleMessage: `Planning des paiements non disponible `,

  // OCPGate
  // src\components\pages\OCPGate.tsx
  start_by: `Commencer par entrer `,

  // CancelPaymentSchedule
  // src\components\molecules\cancelPaymentSchedule\index.tsx
  note: `Note :`,
  // CancellationCode {t(key)}
  FeltPressure: `Le donateur/la donatrice a ressenti un pression du collecteur de dons`,
  NoLongerAfford: `Plus en mesure d'assurer `,
  UnderMinimumWage: `En dessous de l'âge minimum `,
  SpouseObjected: `mon/ma conjoint.e s'y oppose`,
  ChangedMind: `J'ai changé d'avis `,
  IntendedShortTerm: `Le donateur/la donatrice souhaitait faire un don à court terme`,
  IntendedOneTime: `Le donateur/la donatrice voulait faire un seul don `,
  Deceased: `Décédé(e) `,
  Misunderstood: `Mauvaise compréhension de l'engagement `,
  OtherCharities: `Dons à d'autres oeuvres caritatives `,
  PaymentDeclined: `Paiement refusé `,
  Complaint: `Réclamation `,
  AccountClosed: `Compte clôturé `,
  AlreadyDonating: `Don déjà effectué `,
  NoReason: `Aucune explication fournie `,

  // TimeoutModal CP
  // src\components\molecules\timeoutModal\index.tsx
  continue_session: `Gardez-moi connecté(e)`,
  session_timeout: `Session expirée`,
  session_message: `Votre session va expirée dans <1>{{countdown}}</1> secondes.`,
  continue_confirmation: `Souhaitez-vous poursuivre ?`,

  // DonateBox & DonateButton CP
  master_donate_btn: `Faire un don pour cette campagne`,
  team_donate_btn: `Faire un don à l'équipe `,
  team_member_donate_btn: `Faire un don à {{name}}`,

  // bg_image_note: `Cette image apparaîtra en tant qu'arrière-plan sur les pages de collecte de fonds. Elle sera partiellement recouverte par les statistiques de la collecte de fonds (encadré) sur les écrans d'ordinateurs standards et recouverte à quasiment 90 % sur les plus petits écrans.`,
  // sample_campaign_stats: `Voici un exemple de statistiques de collecte de fonds afin d'illustrer comment l'image de fond apparaîtra sur la page de collecte de dons.`,
  // preview: `Prévisualisation `,

  // CampaignSupportersCard & Card
  // src\components\molecules\campaignSupportersCard\index.tsx
  // src\components\atoms\card\index.tsx
  donation_through: `Le don a été fait via `,
  donation: `Don`,

  // view_fundraising_page: `Voir la page de collecte`,

  // once: `une fois `,
  // annually: `annuellement `,

  // OCP DonationGroup > Accordion
  why_donate_monthly: `Quel est l'impact d'un don mensuel ?`,

  // ContactHistory
  // src\components\molecules\contactHistory\index.tsx
  // StatusCode
  Completed: `Terminé `,
  Terminated: `Annulé `,
  Failed: `Échec`,
  TransactionRefund: `Rembourser `,
  InProgress: `En cours`,

  // UpdatePaymentInfo
  // src\components\pages\UpdatePaymentInfo.tsx
  notPaymentMethodsMessage: `Aucun moyen de paiements disponible `,

  // anonymous_checkbox: `Don anonyme `,

  // MembershipDetails
  // src\components\organism\membershipDetails\index.tsx
  include_membership_checkbox: `Inclure mon adhésion avec mon don <2>{{donationFrequency}}</2> de <1>{{donation}}</1>.`,
  membership_order_label: `Sélectionner (facultatif)`,

  // HelpFurther
  // src\components\molecules\helpFurther\index.tsx
  get_involved_title: `S'engager `,
  set_up_fundraising_btn: `Mettre ma campagne de collecte de dons en place `,
  start_fundraising_accordian_title: `Soutenir une campagne `,

  // OrderSummary
  // src\components\atoms\orderSummary\index.tsx
  summary_link_donate_description: `Cliquer sur « Votre don » pour en modifier le montant.`,
  summary_link_subscribe_description: `Cliquez sur « Votre abonnement » pour modifier le montantt`,
  unexpected_error_message: `Une erreur est survenue. Mais votre transaction a été traitée et apparaîtra sur votre relevé. <1>N'essayez plus.</1><br /><br /><2>Cliquez ici</2> ou contactez immédiatement notre équipe de services aux donateurs pour vous informer que vous avez reçu ce message.`,
  applePay_error: `Apple Pay n'est disponible que lorsqu'il est activé sur des appareils compatibles iOS (iPhone/iPad/Mac).`,
  googlePay_error: ` Google Pay n'est pas activé et/ou indisponible sur cet appareil.`,

  // AdditionalInfoDetails
  // src\components\organism\additionalInfoDetails\index.tsx
  additional_info_msg: `Si vous avez des commentaires en lien avec votre don, veuillez les indiquer ici.`,
  notes_or_direction: `Partagez ci-dessous tout commentaire ou directive supplémentaire lié à votre don`,
  nols_additional_info_msg:
    'Si vous avez des commentaires en lien avec votre don, veuillez les indiquer ici.',
  nols_notes_or_direction: '',

  // SignInForm
  // src\components\organism\signInForm\index.tsx
  add_profile_pic: `Ajouter une image de profil :`,
  upload_profile_picture_checkbox: `Je souhaite télécharger une photo de profil `,

  // SeachBar
  // src\components\atoms\searchBar\index.tsx
  sort_by: `Trier par `,
  top_fundraisers: `Les meilleurs collecteurs/collectrices `,
  most_recent: `Plus récents`,
  alphabetical: `Alphabétique `,

  // ProfileImg
  // src\components\atoms\profileImg\index.tsx
  change_profile_picture: `Changer l'image de profil `,

  title_donate_now: `Je veux faire un don pour cette campagne`,
  team_title_donate_now: `Je veux faire un don pour cette équipe `,
  title_start_fundraiser: `Je veux commencer ma propre collecte de dons `,
  title_join_team: `Je veux collecter des dons en tant qu'équipe `,
  title_view_team: `Voir les activités de votre équipe de collecte de dons `,
  title_join_individual: `Je veux collecter des dons individuellement `,
  title_view_individual: `Voir vos activités de collecte de dons `,
  master_title_join_team: `Je veux collecter des dons en tant qu'équipe `,
  team_title_join_team: `Je souhaite rejoindre cette équipe `,
  donate_this_campaign: `Faire un don pour cette campagne`,
  start_fundraiser: `Commencer la collecte maintenant `,
  create_fundraiser: `Créer une\ncollecte `,
  master_join_team: `Créer une\néquipe `,
  team_join_team: `Rejoindre\ncette équipe `,
  view_team: `Voir la page\nde mon équipe `,
  join_individual: `Se joindre en tant\nqu'individu `,
  view_individual: `Voir\nvotre page `,
  view_dashboard: `Voir le tableau de bord `,
  donate_today: `Faire un don\naujourd'hui `,
  select_heading: `Selectionner le don que vous souhaitez effectuer `,
  choose_details: `Choisir les informations que vous souhaitez afficher concernant l'organisation ou la page de collecte :`,
  required_pwd: `Entrez votre mot de passe `,
  pwd_match: `Votre mot de passe doit contenir au minimum 8 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial`,

  postal_code: 'Code Postal',

  start_p2p_btn: 'Commencer la collecte de fonds',

  Membership: 'Adhésion',
  selcetion_label: 'Autre (veuillez sélectionner votre région)',
  pion_additional_info_msg: 'Vos commentaires ici',
  pion_notes_or_direction: `Tapez le nom du missionnaire si vous ne connaissez pas le numéro de compte, ou si vous avez d'autres commentaires :`,
  halifaxyarmouth_additional_info_msg:
    'Veuillez ajouter le nom de votre paroisse',
  halifaxyarmouth_notes_or_direction: `Veuillez ajouter le nom de votre paroisse.`,
  ucc_additional_info_msg:
    'Si vous souhaitez appliquer votre don au total de votre mission et service de communauté de foi, veuillez indiquer ici le nom de votre ville et de votre communauté de foi.',
  ucc_notes_or_direction: `Créditer une communauté religieuse`,

  // ***** NOT IN USE ********
  processingEP: `Nous procédons à votre enregistrement …`,
};
